import React, { useRef } from "react"
import styled from "styled-components"

import { motion, useScroll, useTransform } from "framer-motion"

import { device } from "src/utils"

import { Image } from "src/components/common"
import useMediaQuery from "src/utils/hooks/useMediaQuery"

const WorldsTransition = ({ gallery }) => {
  const isLaptop = useMediaQuery("(min-width: 1024px)")

  const offsetByMedia = {
    mobile: {
      cloud1: "-50vh",
      cloud2: "-25vh",
      tree: "-75vh",
    },
    laptop: {
      cloud1: "-250vh",
      cloud2: "-200vh",
      tree: "-350vh",
    },
  }

  const offset = isLaptop ? offsetByMedia.laptop : offsetByMedia.mobile

  return (
    <StyledWorldsTransition>
      <TransElement image={gallery[0]} offset={["0vh", offset.cloud1]} />
      <TransElement image={gallery[1]} offset={["0vh", offset.cloud2]} />
      <TransElement image={gallery[2]} offset={["0vh", offset.tree]} />
    </StyledWorldsTransition>
  )
}

const StyledWorldsTransition = styled.section`
  position: relative;
  /* z-index: 5; */

  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: min-content;
  background: var(--basecamp-background);
  height: 0;
`

const TransElement = ({ image, offset, children }) => {
  const elemRef = useRef(null)

  const { scrollYProgress } = useScroll({
    target: elemRef,
    offset: ["start end", "end start"],
    // end the 'start' of the ref meets the 'end' of hte container AND when 'end' of target meets 'start' of container.
    // ie. when the elem scrolls into view and when it scroll out
  })

  // scrollYProgress.onChange((latest) => {
  //   console.log("latest:", latest)
  // })

  const y = useTransform(scrollYProgress, [0, 1], offset)

  return (
    <StyledElemenTrans style={{ y }} ref={elemRef} className="trans-elem">
      <Image image={image} />
      {children}
    </StyledElemenTrans>
  )
}

const StyledElemenTrans = styled(motion.div)`
  position: relative;
  z-index: 6;

  &:nth-child(1) {
    max-width: 50%;
    grid-column: 5 / span 6;
    grid-row: 1;
  }
  &:nth-child(2) {
    width: 100%;
    grid-row: 1;
    grid-column: 1 / span 10;
  }
  &:nth-child(3) {
    grid-column: 1 / span 10;
    grid-row: 1;

    position: relative;
    filter: blur(6px);

    &::after {
      content: "";
      position: absolute;
      bottom: -100vh;
      left: 0;
      width: 100%;
      height: 105vh;
      background: var(--basecamp-background);
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }
`

export default WorldsTransition
