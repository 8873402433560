import React, { useEffect } from "react"

import { useRive, useStateMachineInput } from "@rive-app/react-canvas"

//rive-app.github.io/rive-react/?path=/docs/react-runtime-overview--use-rive-hook

const Rive = ({
  src,
  stateMachines: { STATE_MACHINE_NAME, ON_HOVER_INPUT_NAME, onClick },
  pressState,
}) => {
  const { rive, RiveComponent: RiveComponentTouch } = useRive({
    src,
    stateMachines: STATE_MACHINE_NAME,
    autoplay: true,
  })

  // states we need to set the value property to true or false.

  // Hover
  const onHoverInput = useStateMachineInput(
    rive,
    STATE_MACHINE_NAME,
    ON_HOVER_INPUT_NAME
  )

  function onMouseEnter() {
    if (onHoverInput) {
      onHoverInput.value = true
    }
  }

  function onMouseLeave() {
    if (onHoverInput) {
      onHoverInput.value = false
    }
  }

  // Toggle
  const onClickInput = useStateMachineInput(rive, STATE_MACHINE_NAME, onClick)

  const handleClick = () => {
    if (onClickInput) {
      if (onClickInput.value) {
        onClickInput.value = false
      } else if (!onClickInput.value) {
        onClickInput.value = true
      }
    }
  }

  useEffect(() => {
    if (onClickInput) {
      onClickInput.value = pressState
    }
  }, [pressState, onClickInput])

  return (
    <RiveComponentTouch
      className="rive-canvas"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handleClick}
    />
  )
}

export default Rive
