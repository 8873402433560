import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import { Layout, Seo } from "components/common"
import { useContentfulSection, useMeta } from "utils"
import WorldsHero from "src/components/sections/worlds/WorldsHero"
import WorldsTransition from "src/components/sections/worlds/WorldsTransition"
import WorldsBaseCamp from "src/components/sections/worlds/WorldsBaseCamp"

const WorldsPage = ({ data: { worlds } }) => {
  const { base_camp, words_transition } = useContentfulSection(worlds.sections)

  const meta = useMeta(worlds)

  useEffect(() => {
    document.body.style.cssText = `overflow-y: hidden;`
    window.scrollTo(0, 0)

    return () => {
      window.scrollTo(0, 0)
      document.body.style.cssText = `overflow-y: initial;`
    }
  }, [])

  return (
    <Layout hideFooter hideCta>
      <Seo {...meta} />

      <Wrap id="scrollWrap">
        <WorldsHero {...base_camp} />
        <WorldsTransition {...words_transition} />
        <WorldsBaseCamp />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  background: #04070b;
`

export const query = graphql`
  {
    worlds: contentfulPage(pageId: { eq: "worlds" }) {
      ...Page
    }
  }
`

export default WorldsPage
