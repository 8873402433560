import React, { useCallback, useState } from "react"

import map from "lodash/map"

import { Image } from "src/components/common"

import styled from "styled-components"
import classNames from "classnames"

import { motion } from "framer-motion"

import { scroller } from "react-scroll"

import { device } from "src/utils"

import Rive from "src/components/animations/Rive"
import WavePlayer from "src/components/audio/WavePlayer"

import ambientSauceworks from "src/components/audio/ambientSauceworks.mp3"

import mtnSound_0 from "src/components/audio/mtn/Mountain_Sounds_0.wav"
import mtnSound_1 from "src/components/audio/mtn/Mountain_Sounds_1.wav"
import mtnSound_2 from "src/components/audio/mtn/Mountain_Sounds_2.wav"
import mtnSound_3 from "src/components/audio/mtn/Mountain_Sounds_3.wav"
import mtnSound_4 from "src/components/audio/mtn/Mountain_Sounds_4.wav"
import fireWhoosh from "src/components/audio/FireWhoosh.wav"
import fireWhooshCampfireTail from "src/components/audio/FireWhooshCampfireTail.mp3"

import { useRecoilValue } from "recoil"
import { canPlayAudioState } from "src/atoms"

// import rotatePhone from "src/images/worlds/rotatePhone.svg"

const WorldsHero = ({
  backgroundImage,
  mobileBackgroundImage,
  image,
  gallery,
}) => {
  const [playPause, setPlayPause] = useState(null)

  const goToCamp = () => {
    // audioRef.current.audioEl.current.pause()
    setPlayPause(false)

    scroller.scrollTo("basecamp", {
      duration: 2500,
      smooth: "easeInOutCirc",
    })
  }

  let fireAudio

  if (typeof window !== "undefined") {
    // fireAudio = new Audio(fireWhoosh)
    fireAudio = new Audio(fireWhooshCampfireTail)
  }

  const canPlayAudio = useRecoilValue(canPlayAudioState)

  const onMouseEnter = () => {
    if (canPlayAudio) {
      fireAudio.play()
    }
  }

  const onMouseLeave = () => {
    fireAudio.pause()
    fireAudio.currentTime = 0
  }

  return (
    <StyledWorldsHero id="hero">
      <div className="wave-player-container">
        <WavePlayer file={ambientSauceworks} playPause={playPause} />
      </div>

      <div className="nightSky">
        <video
          src={backgroundImage.file.url}
          preload="auto"
          loop
          playsInline
          autoPlay
          muted
        />
      </div>

      <div className="content">
        <Image className="bb-logo" image={image} />

        <div className="ctaWrap">
          <button
            className="cta"
            onClick={goToCamp}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Rive
              src="/rive/basecampEnter.riv"
              stateMachines={{
                STATE_MACHINE_NAME: "State Machine 1",
                ON_HOVER_INPUT_NAME: "MouseOn",
              }}
            />
          </button>
        </div>
        <div className="mobileRotate">
          <p>
            This experience is not optimized for mobile. Use desktop for the
            most magic.
          </p>
        </div>
      </div>

      <div className="mobileMtnWrap">
        <Image image={mobileBackgroundImage} />
      </div>
      <div className="mtnWrap">
        <Mountains gallery={gallery} />
      </div>
    </StyledWorldsHero>
  )
}

const StyledWorldsHero = styled.div`
  min-height: 100vh;
  overflow-y: hidden;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  .wave-player-container {
    position: absolute;
    top: 24px;
    right: 50%;
    transform: translateX(50%);
    z-index: 5;
    width: 100%;
    max-width: 300px;

    @media ${device.laptop} {
      max-width: 320px;
      top: 50px;
      right: 50px;
      transform: translateX(0);
    }
  }

  .nightSky {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &::before {
      content: "";
      background: linear-gradient(rgba(14, 33, 51, 0) 0%, rgb(14, 33, 51) 100%);
      mix-blend-mode: lighten;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
    }

    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .content {
    position: relative;
    z-index: 4;

    display: flex;
    flex-direction: column;
    margin-bottom: 20vh;

    .bb-logo {
      @media ${device.laptop} {
        display: inline-block;
        max-width: 267px;
        margin: 0 auto;
      }
    }

    .ctaWrap {
      position: relative;
      margin: 0 auto;
      margin-top: 16px;
      display: none;

      /* @media screen and (orientation: landscape) {
        display: block;
      } */

      @media ${device.laptop} {
        display: block;
      }

      .cta {
        cursor: pointer;
        /* background: none;
        color: #bdccdd;
        border: none;
        opacity: 0.8;
        border: 1px solid #bdccdd;
        padding: 12px 30px;
        transition: opacity 0.3s ease-in-out;

        &:hover {
          opacity: 1;
        } */

        .rive-canvas {
          transform: translateY(-24px);
          height: 88px;
          width: 88px;
        }
      }
    }

    .mobileRotate {
      text-align: center;

      max-width: 300px;
      margin: 0 auto;

      @media ${device.laptop} {
        display: none;
      }

      p {
        font-size: 1rem;
        line-height: 24px;
        color: var(--basecamp-white);
        margin-top: var(--sp-12);
      }
    }
  }

  .mobileMtnWrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    @media screen and (orientation: landscape) {
      display: none;
    }

    .gatsbyImageWrap {
      .desktop {
        display: block;
      }

      img {
        width: 100%;
      }
    }

    @media ${device.laptop} {
      display: none;
    }
  }
  .mtnWrap {
    display: none;

    @media screen and (orientation: landscape) {
      display: block;
    }

    @media ${device.laptop} {
      display: block;
    }
  }
`

const Mountains = ({ gallery }) => {
  let hoverAudio = []

  if (typeof window !== "undefined") {
    hoverAudio = [
      new Audio(mtnSound_0), // idk dup it...
      new Audio(mtnSound_0),
      new Audio(mtnSound_1),
      new Audio(mtnSound_2),
      new Audio(mtnSound_3),
      new Audio(mtnSound_4),
    ]
  }

  const renderMtn = useCallback(
    () =>
      map(gallery, (img, idx) => {
        return (
          <Mountain
            key={idx}
            img={img}
            idx={idx}
            hoverAudio={hoverAudio[idx]}
          />
        )
      }),
    [gallery]
  )

  return <>{renderMtn()}</>
}

const Mountain = ({ img, idx, hoverAudio }) => {
  // const elemRef = useRef(null)

  // const { scrollYProgress } = useScroll({
  //   target: elemRef,
  //   offset: ["end end", "end start"],
  // })

  // const y = useTransform(scrollYProgress, [0, 1], ["0vh", "-50vh"])

  const canPlayAudio = useRecoilValue(canPlayAudioState)

  const onMouseEnter = () => {
    if (canPlayAudio && hoverAudio) {
      // hoverAudio.volume = 1
      hoverAudio.play()
    }
  }

  // const onMouseLeave = () => {
  //   if (hoverAudio) {
  //     hoverAudio.pause()
  //     hoverAudio.currentTime = 0
  //   }
  // }

  return (
    <span key={img.id}>
      <HoverZone
        className={`zone-${idx}`}
        onMouseEnter={onMouseEnter}
        // onMouseLeave={onMouseLeave}
      />
      <StyledMountain
        className={classNames("mountain", `mtn-${idx}`)}
        // ref={elemRef}
        // style={{ y }}
      >
        <img src={img.file.url} alt="mtn" loading="eager" height={356} />
      </StyledMountain>
    </span>
  )
}

const StyledMountain = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  z-index: 2;

  opacity: 0;
  visibility: 0;
  transition: opacity 0.3s ease-in-out;
  /* max-height: 325px; */

  /* width: 1588px;
  height: 356px; */

  &::before {
    content: "";
    width: 100%;
    height: 75px;

    position: absolute;
    bottom: 0;

    left: 0;
    z-index: 2;
    background: linear-gradient(0deg, #060b04 0%, rgba(6, 11, 4, 0) 100%);
  }

  img {
    width: 100%;
    height: auto;
  }

  .gatsby-image-wrapper {
    /* height: 100%;
    width: 100%; */

    img {
      /* height: 100%;
      width: 100%; */
    }
  }
`

const HoverZone = styled.div`
  height: 100%;
  width: 20%;
  background: none;
  position: absolute;
  z-index: 3;

  &:hover {
    & ~ .mountain {
      opacity: 1;
      visibility: 1;
    }
  }

  &.zone-0 {
    display: none;
    & ~ .mountain {
      opacity: 1;
      visibility: 1;
    }
  }
  &.zone-1 {
    left: 0;
    top: 0;
  }
  &.zone-2 {
    left: 20%;
    top: 0;
  }
  &.zone-3 {
    left: 40%;
    top: 0;
  }
  &.zone-4 {
    left: 60%;
    top: 0;
  }
  &.zone-5 {
    left: 80%;
    top: 0;
  }
`

export default WorldsHero
