import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Unity, useUnityContext } from "react-unity-webgl"
import { motion } from "framer-motion"

import { useIntersectionObserver } from "react-intersection-observer-hook"
import { device } from "src/utils"

import Fullscreen from "src/images/fullscreen.inline.svg"

import MoonLoader from "react-spinners/MoonLoader"

const UnityBaseCamp = ({
  className,
  loaderUrl,
  dataUrl,
  frameworkUrl,
  codeUrl,
}) => {
  const [containerRef, { entry }] = useIntersectionObserver()
  const [gameState, setGameState] = useState(false)

  const props = useUnityContext({
    loaderUrl,
    dataUrl,
    frameworkUrl,
    codeUrl,
  })

  //   const { unityProvider, requestFullscreen } = props
  const {
    unityProvider,
    isLoaded,
    unload,
    requestFullscreen,
    loadingProgression,
  } = props

  const handleFullSreenOn = () => requestFullscreen(true)
  // const handleFullSreenOff = () => requestFullscreen(false)

  const isVisable = entry?.isIntersecting

  const setUnload = async () => {
    if (isLoaded) {
      await unload()
      setGameState(false)
    }
  }

  useEffect(() => {
    if (isVisable) {
      setGameState(true)
    } else {
      setUnload()
    }
  }, [isVisable])

  useEffect(() => {
    return () => {
      setUnload()
    }
  }, [])

  // useEffect(() => {
  //   if (gameState) {
  //     console.log("is loaded.")
  //     // handleFullSreenOn()
  //   }
  // }, [isLoaded])

  const variants = {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0.5 },
  }

  return (
    <>
      {!isLoaded && (
        <UnityLoader>
          <MoonLoader color="#C0CEDF" />
          <p>loading experience... {Math.round(loadingProgression * 100)}%</p>
        </UnityLoader>
      )}
      <UnityWrap
        className={className}
        ref={containerRef}
        variants={variants}
        initial="hidden"
        animate={gameState ? "visible" : "hidden"}
        transition={{
          delay: 1,
          duration: 2,
          easings: "easeInOutCirc",
        }}
      >
        {gameState && <Unity unityProvider={unityProvider} />}

        {isLoaded && (
          <button className="fullScreen" onClick={handleFullSreenOn}>
            <Fullscreen />
          </button>
        )}
      </UnityWrap>
    </>
  )
}

const UnityWrap = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* max-width: 1920px; */
  position: relative;
  z-index: 7;
  min-height: 100%;

  @media ${device.laptop} {
    min-height: 90%;
    /* min-height: 60vh; */
  }
  canvas {
    width: 100%;
    height: 100%;

    @media ${device.laptop} {
      /* border-radius: 32px; */
    }
  }

  .fullScreen {
    position: absolute;
    right: 32px;
    bottom: 32px;
    z-index: 8;
    transition: 0.2s opacity ease-in-out;
    cursor: pointer;
    display: none;

    @media ${device.laptop} {
      display: inline-block;
    }

    &:hover {
      opacity: 0.5;
    }

    svg {
      /* width: 50px;
      height: 50px; */
    }
  }
`

const UnityLoader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;

  p {
    text-align: center;
    margin-top: var(--sp-16);
    font-size: 1rem;
    color: var(--basecamp-white);
  }
`

export default UnityBaseCamp
