import React, { useRef, useState, useEffect, useCallback } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { useSetRecoilState } from "recoil"

import { canPlayAudioState } from "src/atoms"
import Rive from "../animations/Rive"
import gradientBorderNew from "src/styles/gradientBorderNew"

// https://wavesurfer-js.org/docs/

const WavePlayer = ({ file, playPause }) => {
  const wavesurfer = useRef(null)

  const [audioState, setAudioState] = useState(false)
  const [audioReady, setAudioReady] = useState(false)

  const setCanPlayAudioState = useSetRecoilState(canPlayAudioState)

  const handlePlay = useCallback(() => {
    setAudioState(true)
    wavesurfer.current.play()
  }, [])

  const handlePause = useCallback(() => {
    setAudioState(false)
    setCanPlayAudioState(false)
    wavesurfer?.current?.pause()
  }, [setCanPlayAudioState])

  const handlePlayPause = () => {
    setCanPlayAudioState(true)
    if (audioState) {
      handlePause()
    } else {
      handlePlay()
    }
  }

  useEffect(() => {
    if (window && document && !wavesurfer.current) {
      const script = document.createElement("script")
      const body = document.getElementsByTagName("body")[0]
      script.src = "https://unpkg.com/wavesurfer.js"
      body.appendChild(script)
      script.addEventListener("load", () => {
        wavesurfer.current = window.WaveSurfer.create({
          container: "#waveform",
          waveColor: "#222D3F",
          progressColor: "#758BB0",
          cursorColor: "#222D3F",
          barGap: 5,
          barWidth: 2,
          barHeight: 2,
          interact: false,
          cursorWidth: 0,
        })

        wavesurfer.current.load(file)

        wavesurfer.current.on("ready", function () {
          setAudioReady(true)
        })
      })
    }
  }, [file])

  useEffect(() => {
    if (playPause) {
      handlePlay()
    } else {
      handlePause()
    }
  }, [playPause, handlePause, handlePlay])

  return (
    <StyledWavePlayer
      onClick={handlePlayPause}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      initial={{ opacity: 0 }}
      animate={{
        opacity: audioReady ? 1 : 0,
      }}
    >
      <div className="wave-container">
        <div className="wave-wrap">
          <div className="icon">
            <Rive
              src="/rive/sound_toggle_animation.riv"
              stateMachines={{
                STATE_MACHINE_NAME: "State Machine 1",
                onClick: "onClick",
              }}
              pressState={audioState}
            />
          </div>
          <div id="waveform" />
        </div>

        <p className="text">
          {audioState ? "pause audio experience" : "play audio experience"}
        </p>
      </div>
    </StyledWavePlayer>
  )
}

const StyledWavePlayer = styled(motion.button)`
  width: 100%;
  cursor: pointer;

  .wave-container {
    cursor: pointer;
    background: #0e151f;

    border-radius: 16px;
    padding: var(--sp-16);

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    ${gradientBorderNew(
      "linear-gradient(180deg, rgba(176, 188, 206, 0.16) 0%, rgba(138, 152, 175, 0.16) 100%)",
      "1px"
    )};

    .wave-wrap {
      display: flex;
      align-items: center;
      width: 100%;

      .icon {
        .rive-canvas {
          width: 40px;
          height: 40px;
        }
      }

      #waveform {
        width: 100%;
        cursor: pointer !important;
        wave {
          cursor: pointer !important;
          max-height: 45px;
        }
      }
    }

    .text {
      font-weight: 600;
      font-size: 0.875rem;
      text-align: center;
      letter-spacing: -0.04em;
      color: var(--basecamp-white);
    }
  }
`

export default WavePlayer
