import React, { useEffect, useState } from "react"
import UnityBaseCamp from "src/components/unity/UnityBaseCamp"

import { scroller } from "react-scroll"
import styled from "styled-components"
import { motion } from "framer-motion"

import { useIntersectionObserver } from "react-intersection-observer-hook"
import { device } from "src/utils"

import UpArrow from "src/images/upArrow.inline.svg"
// import useMediaQuery from "src/utils/hooks/useMediaQuery"

const WorldsBaseCamp = () => {
  const [containerRef, { entry }] = useIntersectionObserver({ threshold: 1 })

  const [isVisable, setIsVisible] = useState(false)

  // const isDesktop = useMediaQuery("(min-width: 960px) and (hover: hover)")

  let device
  if (typeof window !== "undefined") {
    device = window.navigator.userAgent
  }
  const isMobile = /iPhone|iPad|iPod|Android/i.test(device)

  const gotToTop = () => {
    setIsVisible(false)
    scroller.scrollTo("hero", {
      duration: 2500,
      smooth: "easeInOutCirc",
    })
  }

  const isIntersecting = entry?.isIntersecting

  useEffect(() => {
    if (isIntersecting && !isVisable) {
      setIsVisible(true)
    }
  }, [isIntersecting])

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  const unityDeviceData = {
    desktop: {
      loaderUrl: "/basecamp/desktop/basecamp_desktop.loader.js",
      dataUrl: "/basecamp/desktop/basecamp_desktop.data",
      frameworkUrl: "/basecamp/desktop/basecamp_desktop.framework.js",
      codeUrl: "/basecamp/desktop/basecamp_desktop.wasm",
    },
    mobile: {
      loaderUrl: "/basecamp/mobile/basecamp_mobile.loader.js",
      dataUrl: "/basecamp/mobile/basecamp_mobile.data",
      frameworkUrl: "/basecamp/mobile/basecamp_mobile.framework.js",
      codeUrl: "/basecamp/mobile/basecamp_mobile.wasm",
    },
  }

  const unityProps = isMobile ? unityDeviceData.mobile : unityDeviceData.desktop

  return (
    <StyledWorldsBaseCamp id="basecamp" ref={containerRef}>
      <motion.button
        className="up-arrow"
        onClick={gotToTop}
        variants={variants}
        initial="hidden"
        animate={isVisable ? "visible" : "hidden"}
        transition={{
          duration: 2,
          easings: "easeInOutCirc",
        }}
      >
        <UpArrow />
      </motion.button>

      <UnityBaseCamp {...unityProps} />
    </StyledWorldsBaseCamp>
  )
}

const StyledWorldsBaseCamp = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--basecamp-background);
  position: relative;
  padding: 0;
  height: 85vh;

  @media ${device.laptop} {
    height: 100vh;
    padding: 0 var(--sp-24);
  }

  .up-arrow {
    position: absolute;
    top: 32px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    font-size: 2.5rem;
    transition: 0.2s transform ease-in-out;
    cursor: pointer;
    z-index: 10;

    color: rgba(255, 255, 255, 0.3);

    @media ${device.laptop} {
      color: white;
      top: 96px;
    }

    &:hover {
      opacity: 0.6;
      transform: translate3d(-50%, -10px, 0);
    }
  }
`

export default WorldsBaseCamp
