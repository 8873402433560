import { css } from "styled-components"

// ${gradientBorderNew(
//     "linear-gradient(180deg, rgba(176, 188, 206, 0.16) 0%, rgba(138, 152, 175, 0.16) 100%)"
//   )};

const gradientBorderNew = (gradient, border = "1px") => css`
  &::before {
    content: "";
    pointer-events: none;
    user-select: none;
    position: absolute;
    inset: 0px;
    border-radius: inherit;
    padding: ${border};

    background: ${gradient};

    -webkit-mask: linear-gradient(black, black) content-box content-box,
      linear-gradient(black, black);
    -webkit-mask-composite: xor;

    @-moz-document url-prefix() {
      display: none;
    }
  }
`

export default gradientBorderNew
